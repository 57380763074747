import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  Hero,
  theme,
  Container,
  Box,
  Flex,
  Title,
} from '@components';

const Map = styled.div`
  width: 100%;
  background-color: ${theme.colors.lightBackground};
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height: 0;

  & iframe {
    left:0;
    top:0;
    height:100%;
    max-height: 600px;
    width:100%;
    position:absolute;
  }
`;

const ContactPage = ({ data }) => {
  const { frontmatter = {} } = data.markdownRemark;

  const {
    hero,
    seo,
    locations,
  } = frontmatter;

  return (
    <>
      <Hero
        {...hero}
        seo={seo}
        space={{
          pb: [2, 3],
        }}
      />
      <Container py={0} my={[2, 3, 4]}>
        <Flex flexDirection={['column', 'column', 'row']}>
          {locations.map(({ title, markdown }, index) => (
            <Box
              key={title}
              width={[1, 1, 1 / 2]}
              fontSize={[1, 2, 3]}
              textAlign={['center', 'center', 'initial']}
              pl={index % 2 ? 0 : [0, 0, 0, 5]}
              pr={!(index % 2) ? [2, 2, 3, 4] : 0}
              mb={[3, 3, 0]}
            >
              {title?.text && (
                <Title color={title.color}>
                  {title.text}
                </Title>
              )}
              <div dangerouslySetInnerHTML={{ __html: markdown }} />
            </Box>
          ))}
        </Flex>
      </Container>
      <Map>
        <iframe
          title="map"
          src="https://www.google.com/maps/d/embed?mid=1_g59cvoiv1zb1DQ6Hx9Y4TNCNO0smxmu"
          // src="https://maps.google.com/maps?q=mi%C4%99dzyleska%204&t=&z=15&ie=UTF8&iwloc=&output=embed"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Map>
    </>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          markdown
        }
        locations {
          title {
            color
            text
          }
          markdown
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
